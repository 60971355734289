//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkHeading',
  props: {
    level: {
      required: true,
      type: [Number, String],
      default: 'h2',
      validator(value) {
        const headlines = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
        return headlines.includes(`h${value}`);
      },
    },
    size: {
      type: [Number, String],
      default: 3,
      validator(value) {
        const sizes = [1, 2, 3, 4, 5, 6, 7];
        return sizes.includes(parseInt(value));
      },
    },
    weight: {
      type: String,
      default: 'normal',
      validator(value) {
        const weights = ['light', 'normal', 'medium', 'semibold', 'bold'];
        return weights.includes(value);
      },
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    textInSpan: {
      type: String,
      default: '',
    },
    font: {
      type: String,
      default: 'font-display',
    },
  },
  setup() {
    const hasDescription = (descriptionProp, slots) => {
      return !!descriptionProp || slots().description;
    };
    const headingTag = (headingLevel) => {
      return headingLevel ? `h${headingLevel}` : `h2`;
    };
    const headingSize = (headingSize, headingLevel) => {
      switch (headingSize ? headingSize : headingLevel) {
        case 1:
          return 'text-4xl md:text-5xl lg:text-6xl';
        case 2:
          return 'text-3xl md:text-4xl lg:text-5xl';
        case 3:
          return 'text-2xl md:text-3xl lg:text-4xl';
        case 4:
          return 'text-1xl md:text-2xl lg:text-3xl';
        case 5:
          return 'text-1xl md:text-2xl';
        case 6:
          return 'text-xl';
        case 7:
          return 'text-lg';
        default:
          return 'text-base';
      }
    };
    const headingWeight = (headingWeight) => {
      return `font-${headingWeight}`;
    };
    return {
      hasDescription,
      headingTag,
      headingSize,
      headingWeight,
    };
  },
});
